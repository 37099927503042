.post-list-panel {
    padding: 20px;
    text-align: center;
    background-color: #fafafa;
    border: 1px #e9e9e9;
    border-radius: 2px;
}

.news-posts-page .news-settings .ant-card-grid {
    padding: 12px;
}

.news-posts-page .ant-tree-node-selected {
    background-color: white !important;
}

.newsposts-emulator-header {
    margin-left: 10px;
}

.newsposts-emulator-content {
    margin: 20px 20px;
}

.news-posts-page-content-details label {
    opacity: 0.5;
    font-style: italic;
}

.mock-accordion{
    width: 100%;
    overflow: hidden;
    margin-bottom: 10px;
}

.mock-accordion-label {
    padding: 16px 10px;
}

.mock-accordion-content {
    padding: 0 10px;
    transition: all 2s ease-in-out;
}

.mock-accordion-label {
    opacity: 1 !important;
    font-style: initial !important;
    display: block;
    color: black;
    font-weight: bold;
    cursor: pointer;
    border-top: 2px black solid;
    border-bottom: 2px black solid;
}

.mock-accordion-label:after{
    content: ' \02C5';
    float: right;
}

.mock-accordion-input{
    display: none;
}

.mock-accordion-content {
    max-height: 0;
    height: 0;
}

.mock-accordion-input:checked + .mock-accordion-label:after{
    content: ' \02C4';
    float: right;
    transition: all 0.3s ease-in-out;
}

.mock-accordion-input:checked ~ .mock-accordion-content{
    max-height: 2000px;
    height: auto;
}