.table-location>tbody>tr>td {
    border: none;
    padding: 0px 8px;
}
.table-location .search-label {
    text-align: right;
    padding-top: 5px;
}
.table-location .filter-label {
    padding-right: 0;
    padding-top: 5px;
}
.btn-search {
    background-color: #ffffff;
    border-right: 1px solid #d2d6de;
    border-top: 1px solid #d2d6de;
    border-bottom: 1px solid #d2d6de;
    border-left: none;
}
.btn-search:hover {
    background-color: #ffffff;
    background-color: #ffffff;
    border-right: 1px solid #d2d6de;
    border-top: 1px solid #d2d6de;
    border-bottom: 1px solid #d2d6de;
}
.btn-search svg {
    color: #6f6d6d;
}

.btn-search:active,
.btn-search:focus{
    background-color: #ffffff;
    border-color: #d2d6de;
}
.input-group-search>
.input-group-btn > .btn {
    outline: none;
}
.input-group-search .form-control {
    border-right-color: #ffffff;
}

.location-plasma {
    padding: 15px 20px 0;
}

.plasma .box-header {
    border-bottom: 1px solid #f4f4f4;
    padding-bottom: 0;
}

.nav-location-tab {
    background-color: #ffffff;
    border-radius: 5px;
}
.nav-location-tab>li>a {
    padding: 12px 40px;
    font-family: 'Roboto';
    border-top: none;
    color: #b4bac2;
    padding: 12px 30px;
}
.nav-location-tab>li>a:hover,
.nav-location-tab>li>a:active,
.nav-location-tab>li>a:focus {
    background-color: transparent;
    color: #e52d47;
}

.nav-location-tab>li.active>a,
.nav-location-tab>li.active>a:hover,
.nav-location-tab>li.active>a:focus {
    color: #e52d47;
    background-color: transparent;
    border-top-color: transparent;
    border-bottom: 3px solid #e52d47;
    font-weight: normal;
}

.nav-location-tab>li>div{
    padding: 12px 30px;
    display: flex;
    align-items: center;

}
.nav-location-tab>li>div>a {
    font-family: 'Roboto';
    border-top: none;
    color: #b4bac2;
}
.nav-location-tab>li>div>a:hover,
.nav-location-tab>li>div>a:active,
.nav-location-tab>li>div>a:focus {
    background-color: transparent;
    color: #e52d47;
}

.nav-location-tab>li.active>div>a,
.nav-location-tab>li.active>div>a:hover,
.nav-location-tab>li.active>div>a:focus {
    color: #e52d47;
    background-color: transparent;
    border-top-color: transparent;
    border-bottom: 3px solid #e52d47;
    font-weight: normal;
}

.table-plasma-content>tbody>tr>td {
    padding: 10px 8px;
    color: #545e67;
    white-space: nowrap;
}

.btn-create-new {
    margin-top: 8px;
}

.h-action, .col-action {
    text-align: center;
}

.col-action img {
    margin-left: 6px;
    margin-right: 6px;
    width: 15px;
}

.modal-freeze-confirm .modal-content{
    border-radius: 5px;
}

.modal-freeze-confirm .confrim-txt{
    font-size: 18px;
    margin-top: 12%;
    margin-bottom: 12%;
}

.modal-freeze-confirm .section-btn-comfirm .btn-blue{
    font-size: 15px;
    width: 40%;
    margin: 0 auto;
}

.box-location {
    padding-top: 30px;
    padding-bottom: 30px;
}

.box-location .status-invalid {
    margin-left: 30px;
}

.box-location .row-lbl {
    margin-top: 7px;
    white-space: nowrap;
}
.row-lbl span { 
    position: absolute;
    left: -10px;
    top: 2px; }
.btn-blue-custom {
    width: 210px;
    font-size: 14px;
}

.photo-wrp {
    padding-bottom: 10px;
}
.photo-wrp .col-md-3 {
    padding-top: 10px;
}
.location-btn {
    padding: 20px;
}
.location-btn .btn-submit {
    font-size: 14px;
    width: 230px;
}
.rm-img {
    position: absolute;
    padding-left: 3px;
    padding-right: 3px;
    cursor: pointer;
    right: 0;
    top: 5px;
}
.uploaded-img {
    float: left;
}
.photo-preview {
    margin-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
}

.photo-preview .img-wrp {
    float: left;
    width: 20%;
    position: relative;
    padding-right: 10px;
    padding-top: 10px;
}
.md-input {
    width: 15%;
    float: left;
}
.box-location .mid-dash {
    width: 15px;
    float: left;
    border-bottom: 1px solid #868686;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 16px;
}

.btn-add-wrp {
    width: 40%;
    position: relative;
    margin-top: 30px;
    margin-bottom: 30px;
}

.input-moc, .input-mob, .input-abp {
    width: 100%;
    position: relative;
    float:left;
    margin-right: 15px;
}
.span-moc, .span-mob, .span-abp {
    padding-top: 5px;
    float: left;
}

.box-row-1 {
    width: 43.5%;
    position: relative;
    margin-bottom: 15px;
}

.box-row-1 .icon-calendar {
    position: absolute;
    right: 25px;
    top: 8px;
    width: 16px;
}

.box-row-2 {
    width: 52%;
    margin-bottom: 15px;
}

.box-row-2 .sub-lbl{
    padding-left: 35px;
    padding-top: 5px;
    white-space: nowrap;
}

.pod-input input, .pod-input span {
    float: left;
}

.pod-input input {
    width: 50px;
    text-align: center;
    padding: 5px;
}

.pod-input span {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 5px;
}

.box-row-3 {
    margin-bottom: 15px;
    position: relative;
}
.mid-lbl {
    padding-top: 4px;
}

.mid-lbl label {
    font-family: 'roboto-Regular';
    white-space: nowrap;
}

.input-ait {
    width: 40%;
    float: left;
    position: relative;
}

.select-ait {
    width: 20%;
    float: left;
    position: relative;
    margin-left: 15px;
}

.select-ait option {
    color: #717171;
}

.dtr-wrp .ing-1 {
    width: 40%;
    float: left;
}

.title-page {
    font-size: 20px;
}

.dtr-wrp .input-group input {
    border-right: none;
}

.dtr-wrp .input-group .input-group-addon {
    border-left: none;
    border-radius: 0 4px 4px 0;
    color: #cccccc;
    font-size: 11px;
}

.dtr-wrp .ing-2 {
    width: 20%;
    margin-left: 15px;
    float: left;
}

.dtr-wrp .div-icon {
    width: 20px;
    float: left;
    margin-left: 15px;
    margin-top: 3px;
}
.div-icon img {
    width: 15px;
    cursor: pointer;
}

.dtr-wrp .btn-add-wrp {
    margin-bottom: 15px;
}

/* region style */
.vselected {
    padding: 6px 10px;
    border: 1px solid #009bdd;
    display: inline-block;
    border-radius: 4px;
    margin-right: 5px;
    margin-bottom: 5px;
}

.vselected .fa-times {
    margin-left: 15px;
    color: #009bdd;
    cursor: pointer;
    vertical-align: middle;
}

/* The container */
.cb-container {
    display: block;
    position: relative;
    padding-left: 18px;
    margin-bottom: 20px;
    cursor: pointer;
    font-size: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.cb-container input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: #eaeaea;
    border-radius: 2px;
}

/* On mouse-over, add a grey background color */
.cb-container:hover input ~ .checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.cb-container input:checked ~ .checkmark {
    background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.cb-container input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.cb-container .checkmark:after {
    left: 5px;
    top: 2px;
    width: 5px;
    height: 9px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.region-btn-add .btn {
    width: 40%;
    font-style: bold;
}

.lc-sl-placeholder {
    color: #989898 !important;
}
.setting-container {
    padding-top: 30px;
    padding-bottom: 30px;
}
input[type="radio"].csd-date {
    margin-top: 2px;
}
input[type="radio"].csd-period-date {
    margin-top: 2px;
}
.close {
    width: 22px;
    opacity: 10;
}
.close:hover {
    opacity: 10;
}
.content-confirm {
    font-size: 15px;
    width: 75%;
    margin: 0 auto;
}
.modal-header {
    border-bottom: 0;
    padding: 25px;
}
.modal-content {
    width: 90%;
}
.label-createPlasma {
    padding-left: 3.5em;
}
.phoneNumber-createPlasma {
    display: flex;
}
.phoneNumber-createPlasma input:first-child {
    width: 10%;
    margin-right: 1em;
}
.box-header-title { padding: 10px 20px; }
.header-title-region {
    font-size: 15px;
    color: #333;
    font-family: 'Roboto';
}
.icon-remove {
    width: 17px;
    cursor: pointer;
}
.input-group-addon-trash {
    border: none;
    padding: 6px 0px 6px 12px;
}
.l-view-font { font-family: "roboto" }

/* update 21-01-2020 */
@media screen and (max-width: 768px) {
    .box-row-1 {
        width: 46.5%
    }
    .box-row-2 .sub-lbl {
        padding-bottom: 5px;
    }
    .mid-lbl {
        padding-bottom: 5px;
    }
    .md-input {
        width: 20%;
    }
    .input-moc, .input-mob {
        width: 50%;
    }
    .gs-appointment-label {white-space: normal;}
    .input-ait { width: 50%; }
}
.fa-star {
    font-size: 20px;
    margin: 0 3px;
}
.checked-star {
    color: orange;
}
.unchecked-star {
    color: #e2e2e2;
}
.v-rate-num {
    padding: 1px 6px;
    border: 1px solid #e52d47;
    margin: 0 10px;
    border-radius: 50%;
    color: #e52d47;
}
.v-input-date input[type="text"]{
    border-right-color: transparent;
}
.fb-h-filter {
    list-style: none;
    margin: 0 10px;
}
.fb-h-filter li { 
    float: left;
    margin: 0 10px;
}
.fhr-filter {
    padding: 5px 35px;
    outline: none;
    background-color: #f5f5f5;
}
.fhr-img {
    margin-right: 25px;
    border-radius: 50%;
    width: 75px;
    height: 75px;
}
.fhr-wrap-msg {
    padding: 20px 15px;
}
.fb-h-filter li .active {
    border: 1px solid #009bdd;
    color: #009bdd;
}
.fb-h-filter li button:hover {
    border: 1px solid #009bdd;
    color: #009bdd;
    background-color: #f5f5f5;
}

.l-wrap-flag {
    position: relative;
}
.l-flage-us-icon {
    background-image: url('../assets/images/flags.80a4ed47.png');
    width: 20px;
    height: 10px;
    background-repeat: no-repeat;
    position: absolute;
    z-index: 4;
    top: 9px;
    left: 5px;
    background-position: -5241px 0;
}
.l-flage-code {
    position: absolute;
    left: 31px;
    z-index: 4;
    top: 4px;
}
.avatar-feedback p {
    margin-top: 0;
    margin-bottom: 0;
    line-height: 25px;
}
.d-icon-date { 
    vertical-align: sub;
    cursor: pointer;
}
.star-box div{
    line-height: 25px;
}
.space-img { margin: 0 5px }