.disabled-time {
    background-color: #f5f5f5; /* Change the background color of the cell */
    pointer-events: none; /* Disable pointer events to prevent user interaction */
}

.ant-picker-time-panel .ant-picker-content {
    height: 252px
}

.musk-cover {
    position: absolute;
    top: 22px;
    height: 778px;
    background: firebrick;
    width: 100%;
    opacity: 80%;
    z-index: 999;
}

.draggable-event {
    cursor: grab !important;
}

.non-interaction-event {
    cursor: pointer !important;
}