.section {
    padding-top: 35px;
}

.section-title {
    font-size: 20px;
    font-weight: bold;
    text-align: left;
}

.section-content {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 1px 1px 4px #0000000D;
    border-radius: 10px;
}

.section-column {
    column-count: 1;
    padding: 15px 25px;
    column-gap: 100px;
}

@media (min-width: 768px) {
    .section-column {
        column-count: 2;
    }

    .section-title {
        font-size: 22px;
    }
}

.update-description {
    margin: 0.5em;
    width: 50%;
}

#submit-buttons {
    margin: 0 30%;
}

.staff-detail {
    position: relative;
    display: flex;
    align-items: center;
}

.staff-detail-icon {
    margin-right: 0.3em;
    display: block;
}

.staff-detail .ant-typography-secondary {
    font-weight: bold;
    color: var(--bs-modal-color);
}

.staff-detail input {
    width: 100%;
}

.staff-introduciton {
    border-bottom: 1px solid rgba(210, 207, 207, 0.30);
    display: inline-block;
    width: 100%;
}

.staff-textarea-label {
    padding: 8px 0;
}

.MuiInput-root:before {
    border-bottom: 1px solid rgba(210, 207, 207, 0.30)  !important;
}

.select-tag {
    border-bottom: 1px solid rgba(210, 207, 207, 0.30);
    display: inline-block;
    width: 100%;
}

.ant-select-item-option-content {
    white-space: break-spaces !important;
    word-break: break-word !important;
}