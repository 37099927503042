.pagination>.active>a,
.pagination>.active>a:focus,
.pagination>.active>a:hover,
.pagination>.active>span,
.pagination>.active>span:focus,
.pagination>.active>span:hover {
    background-color: transparent;
    border-color: #00c0ef;
    border-radius: 3px;
    color: #00c0ef;
}

.pagination>.disabled>a,
.pagination>.disabled>a:focus,
.pagination>.disabled>a:hover,
.pagination>.disabled>span,
.pagination>.disabled>span:focus,
.pagination>.disabled>span:hover {
    background-color: transparent;
}

.pagination>li {
    display: inline-block;
    padding: 0 5px;
}

.pagination>li>a, .pagination>li>span {
    background-color: transparent;
    border: 1px solid #eee;
    border-radius: 3px;
}

.pagination>li>a:focus,
.pagination>li>a:hover,
.pagination>li>span:focus,
.pagination>li>span:hover {
    color: #00c0ef;
    background: transparent;
    border: 1px solid #00c0ef;
}

.input-group .input-group-addon {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    color: #c3c3c3;
    border-bottom-color: #e8e8e8;
    border-right-color: #e8e8e8;
    border-top-color: #e8e8e8;
}

.input-group-sm > .form-control, .input-group-sm > .input-group-addon, .input-group-sm > .input-group-btn > .btn {
    height: 28px;
    padding: 4px 10px;
    border-bottom-color: #e8e8e8;
    border-right-color: #e8e8e8;
    border-top-color: #e8e8e8;
}

.input-group-search>
.input-group-btn > .btn {
    outline: none;
}
.input-group-search .form-control {
    border-right-color: #ffffff;
}
