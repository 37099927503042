.appointment-management {
    padding-block: 2px;
    background: #f5f5f5;
}

.appointment-management .rbc-calendar {
    height: calc(100vh - 60px - 32px - 38px);
}

.appointment-management .rbc-timeslot-group {
    min-height: calc((100vh - 60px - 32px - 34px - 50px - 32px - 6px) / 4);
}

.appointment-management .rbc-event-content {
    font-size: 12px;
    font-weight: 600;
}

.appointment-management .rbc-allday-cell {
    display: none;
}

.appointment-management .rbc-header {
    background: #f5f5f5;
    border-bottom: none;
}

.appointment-management .rbc-header.rbc-today {
    background: #fff;
    color: #5B5FC7;
}

.appointment-management .rbc-time-slot:not(.disabled-time) {
    background: #fff;
}

.appointment-management .rbc-day-slot.rbc-time-column.rbc-now.rbc-today {
    background: #fff;
}

.appointment-management .react-datepicker__navigation {
    top: 0.5em;
    line-height: 1.7em;
    border: 0.45em solid transparent;
}

.appointment-management .react-datepicker__day--outside-month {
    color: lightgrey;
}

.appointment-management .react-datepicker__day--disabled {
    color: lightgrey;
}

.appointment-management .react-datepicker__day {
    border-radius: 0;
    font-weight: 500;
}

.appointment-management .react-datepicker__day-name, .react-datepicker__day {
    width: 2.2em;
    line-height: 2.2em;
    margin: 0;
    color: black;
}

.appointment-management .react-datepicker__week:hover .react-datepicker__day:not(.in-day-view) {
    background: #DCE0FA;
}

.appointment-management .react-datepicker__day:hover {
    background: #b8bdf8 !important;
}

.appointment-management .react-datepicker__day--selected::before {
    content: "🎯";
    font-size: 8px;
    position: absolute;
    margin-left: -9px;
}

.appointment-management .react-datepicker__day--today {
    color: white;
    background: #5B5FC7 !important;
}

.appointment-management .react-datepicker__day--same-week {
    background: #DCE0FA;
}

.appointment-management .rbc-event-content {
    width: calc(100% - 16px) !important;
}

.appointment-management .rbc-event-label {
    display: none;
}

.appointment-window .rbc-event-label {
    display: none;
}