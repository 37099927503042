.section {
    padding-top: 35px;
}

.section-title {
    font-size: 20px;
    font-weight: bold;
    text-align: left;
}

.section-content {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 1px 1px 4px #0000000D;
    border-radius: 10px;
}

.section-column {
    column-count: 1;
    padding: 15px 25px;
    column-gap: 100px;
}

.view-result-link {
    padding-left: 10px;
    font-weight: bold;
}

.section-link {
    display: inline-flex;
    align-items: baseline;
}

.white-header-table thead[class*="ant-table-thead"] th{
    background-color: white !important;
}

.mb-0-table {
    margin-bottom: 0 !important;
}

.ant-table-wrapper {
    margin-bottom: 0px !important;
}

.ant-table-cell {
    background-color: #fff;
}

.ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    background-color: unset !important;
}
.create-row {
    text-align: right;
}

.btn-create {
    width: 140px !important;
    margin-bottom: 28px !important;
} 
.btn-blue , .btn-blue.ant-btn:focus{
    background-color: #5284C2 !important; 
    color: #ffffff !important;
}

.btn-blue:hover{
    background-color: #6ea0dd !important;
    color: #ffffff !important;
}

.btn-delete {
    padding-left:10px
}
.btn-cancel {
    margin-right: 10px;
}

.btn-container {
    text-align: right;
}
.red {
    color:red !important;
}
.penetrable-click-modal {
    pointer-events: none;
}
@media (min-width: 768px) {
    .section-column {
        column-count: 2;
    }
    .section-title {
        font-size: 22px;
    }
}

.profile .ant-upload-wrapper {
    text-align: right;
}

.ant-table-thead .bioid-grey-cell {
    background-color: #E1E1E1 !important;
    color: black !important;
}

.ant-table-thead .bioid-green-cell {
    background-color: #73A952 !important;
    color: white !important;
}

.ant-table-thead .bioid-purple-cell {
    background-color: #61397C !important;
    color: white !important;
}

.ant-table-thead .bioid-blue-cell {
    background-color: #4F79AD !important;
    color: white !important;
}