.move-up-button{
    position: absolute;
    top: 8px;
    left: 140px
}

.move-down-button{
    position: absolute;
    top: 8px;
    left: 172px
}

.delete-button {
    position: absolute;
    top: 8px;
    right: 68px
}

.move-up-description-button{
    position: absolute;
    top: 4px;
    right: 88px;
}

.move-down-description-button{
    position: absolute;
    top: 4px;
    right: 112px;
}

.delete-description-button {
    position: absolute;
    top: 4px;
    right: 4px;
}

.flashing {
    animation: blinker 1s 5 forwards;
}

@keyframes blinker {
    from { background-color: #fafafa; }
    50% { background-color: lightgray; }
    to { background-color: #fafafa; }
}

.license-agreement-page label {
    opacity: 0.5;
    font-style: italic;
}