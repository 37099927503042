.vselected {
    padding: 6px 10px;
    border: 1px solid #009bdd;
    display: inline-block;
    border-radius: 4px;
    margin-right: 15px;
    margin-bottom: 15px;
}

.vselected .fa-times {
    margin-left: 15px;
    color: #009bdd;
    cursor: pointer;
}