.modal-custom-mobile {
    width: 380px;
    height: 880px;
    background: rgba(0,0,0,0);
    background-color: rgba(0,0,0,0);
    margin-top: 0;
    margin-bottom: 0;
}
.modal-custom-publish-mobile {
    width: 480px;
    background: rgba(0,0,0,0);
    background-color: rgba(0,0,0,0);
    margin-top: 0;
    margin-bottom: 0;
}
.modal-custom-region-mobile {
    background: rgba(0,0,0,0);
    background-color: rgba(0,0,0,0);
    margin-top: 0;
    margin-bottom: 0;
}
.modal-custom-content {
    width: 300px;
    margin-top: 0;
    margin-bottom: 0;
}
/* tab marketing */
.nav-marketing-tab {
    background-color: #ffffff;
    border-radius: 5px;
}
.nav-marketing-tab>li>a {
    padding: 12px 40px;
    font-family: 'Roboto';
    border-top: none;
    color: #b4bac2;
}
.nav-pills>li.active>a {
    font-weight: normal;
}
.nav-marketing-tab>li.active>a,
.nav-marketing-tab>li.active>a:hover,
.nav-marketing-tab>li.active>a:focus {
    color: #e52d47;
    background-color: transparent;
    border-top-color: transparent;
    border-bottom: 3px solid #e52d47;
}
.nav-marketing-tab>li>a:hover,
.nav-marketing-tab>li>a:active,
.nav-marketing-tab>li>a:focus {
    color: #e52d47;
    border-bottom: none;
    background-color: #ffffff;
}
.nav-marketing-tab > li + li {
    margin-left: 0;
}
.btn-new-survey {
    margin-top: 3px;
}
/* Your Surveys */
.table-marketing>tbody>tr>th {
    padding: 10px 8px;
    border-top: none;
}
.table-marketing>tbody>tr>td {
    padding: 10px 8px;
    vertical-align: middle;
    color: #545e67;
}
.icon-sign-red {
    color: #e52d47;
    font-size: 8px;
    vertical-align: middle;
}
.icon-edit {
    width: 15px;
}
.icon-sign-waiting {
    color: #f7be45;
    font-size: 8px;
    vertical-align: middle;
}
.table-filer>tbody>tr>td {
    vertical-align: sub;
    text-align: right;
    border: none;
}
.table-filer>tbody>tr>td:first-child {
    text-align: left;
}
.status-reviewed span {
    font-size: 18px;
}
.icon-sign-waiting-publish {
    color: #0ecea8;
    font-size: 9px;
    vertical-align: middle;
}
.space-td-action {
    width: 8%;
}
.icon-view {
    width: 15px;
    vertical-align: -webkit-baseline-middle;
}

/* pop up phone */
.main-list-style {
    padding-left: 12px;
}
.sub-list-style {
    list-style: none;
    padding: 0;
}
.box-modal-text {
    position: absolute;
    top: 39%;
    left: 50%;
    width: 42%;
    transform: translate(-50%, -50%);
    text-align: left;
}
.logo-text {
    position: absolute;
    left: 65px;
}
.logo-text p {
    margin: 0;
    color: #7a7a7a;
}
.logo-text p:first-child {
    font-size: 14px;
    color: #06c;
}
.box-logo {
    margin-left: 0;
}
.modal-content-phone {
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color: transparent;
}
.icon-close {
    cursor: pointer;
}

/* page create promotion news */
.table-news-form>tbody>tr>td {
    border: none;
}
.table-news-form>tbody>tr>td:nth-of-type(1) {
    width: 20%;
    padding-left: 0;
}
.width-input-box {
    width: 65%;
}
.box-marketing-bd-form {
    padding-left: 40px;
    padding-right: 40px;
}
.table-feedback>tbody>tr>td {
    border: none;
}
.table-feedback>tbody>tr>.feedback-label {
    width: 20%;
    vertical-align: middle;
    padding-left: 0;
}
.td-width {
    width: 20%;
    padding-left: 0 !important;
}
.label-middle {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    padding-left: 0 !important;
}
.table-feedback>tbody>tr>td>.radio {
    margin-top: 0;
    margin-bottom: 0;
}
.table-feedback>tbody>tr>.feedback-yes-radio {
    padding-top: 0;
}
.table-feedback>tbody>tr>.feedback-no-radio {
    padding-bottom: 0;
}
.num-order-list {
    color: #e52d47;
    border: 1px solid #e52d47;
    padding-left: 7px;
    padding-right: 7px;
    border-radius: 50px;
    padding-top: 1px;
    margin-right: 10px;
    padding-bottom: 1px;
}
.num-order-with-label {
    position: relative;
    right: 36px;
}
.select-btn-add-qt {
    width: 60%;
    text-align: initial;
    margin-left: 5%;
    text-align: center;
    margin-top: 20px;
}

.label-title span {
    color: #e52d47;
    vertical-align: sub;
}
.box-header-promotion {
    padding: 10px 20px;
}
.table-news-promote>tbody>tr>td {
    font-family: 'Roboto';
    font-size: 12px;
    border: none;
}
.table-news-promote>tbody>tr>td>span:first-child {
    color: #e52d47;
    vertical-align: sub;
}
.table-news-promote>tbody>tr>td:nth-of-type(1) {
    width: 20%;
    padding-left: 0;
}
.table-news-promote>tbody>tr>td:nth-of-type(2) {
    width: 9%;
}
.table-news-promote>tbody>tr>td:nth-of-type(3) {
    width: 16%;
}
.promote-checkbox {
    margin-top: 0;
    margin-bottom: 0;
}
.promote-checkbox label {
    font-family: 'Roboto';
    font-size: 12px;
}
.promote-checkbox, input[type="checkbox"] {
    margin: 2px 0 0;
}
.promote-text-note {
    color: #7a7a7a;
    font-weight: normal;
}

.icon-sign-required {
    color: #e52d47;
    vertical-align: sub;
}
.table-target-audience>tbody>tr>td {
    font-size: 12px;
    border: none;
    font-family: 'Roboto';
}
.table-target-audience>tbody>tr>td:nth-of-type(1) {
    width: 20%;
    padding-left: 0;
}
.table-target-audience>tbody>tr>.td-location {
    padding: 15px 8px;
}
.table-target-audience>tbody>tr>.td-city-selection {
    font-weight: normal;
    font-family: 'roboto-Regular';
}
.table-target-audience>tbody>tr>.td-padding {
    padding-bottom: 25px;
}
.box-btn-lg {
    width: 70%;
    text-align: center;
    margin: 0 auto;
    position: relative;
    right: 5%;
    margin-bottom: 20px;
    margin-top: 20px;
}
.box-btn-lg .btn-blue {
    width: 25%;
    padding-top: 9px;
    padding-bottom: 9px;}
.box-btn-lg .btn-submit {
    padding-top: 9px;
    padding-bottom: 9px;
    width: 53%;
}
.box-btn-lg a:nth-of-type(1) {
    margin-right: 10px;
}
.box-btn-lg a:nth-of-type(2) {
    margin-left: 10px;
}
/* campaigns page */
.add-list-city {
    counter-reset: city-counter;
    list-style: none;
}
.add-list-city li {
    margin-bottom: 10px;
}
.add-list-city li::before {
    content: counter(city-counter) ". ";
    counter-increment: city-counter;
    line-height: var(--size);
    text-align: center;
    position: absolute;
    margin-top: 10px;
    left: 30px;
}
.icon-trash {
    width: 17px;
    cursor: pointer;
}
.btn-submit {
    padding: 8px 50px;
}
.list-input-group .input-group-addon {
    border: none;
}
.location-city select {
    width: 64%;
}

.location-state .state {
    display: inline-block;
    width: 30%;
    margin-right: 30px;
}
.cash,
.point {
    display: inline-block;
    width: 58px;
    text-align: center;
}
.radio {
    margin-bottom: 20px;
}
.radio-status-phone {
    margin-bottom: 10px;
}
.table-rewards>tbody>tr>td {
    border: none;
}
.table-rewards>tbody>tr>td:first-child {
    padding-left: 0;
}
.table-rewards .checkbox {
    margin-top: 6px;
}
.label-text-rigth  {
    font-weight: normal;
    vertical-align: sub;
}
.reward-text-box {
    width: 30%;
}
.label-acv {
    vertical-align: middle;
}

/* modal publish survey and promotion new confirm */
.modal-survey-confirm .modal-content,
.modal-news-confirm .modal-content {
    border-radius: 5px;
    padding: 15px;
}
.modal-header {
    border-bottom: none;
}
.modal-survey-confirm .confrim-txt,
.modal-news-confirm .confrim-txt {
    font-size: 17px;
    padding-bottom: 49px;
    padding-top: 20px;
}

.modal-survey-confirm .section-btn-comfirm .btn-blue,
.modal-news-confirm .section-btn-comfirm .btn-blue {
    font-size: 16px;
}

/* review promotion */
.table-review-promotion>tbody>tr>th {
    padding-left: 0;
}
.table-review-promotion>tbody>tr>th:nth-last-of-type(1) {
    width: 20%;
}
.table-review-promotion>tbody>tr>td,
.table-review-promotion>tbody>tr>th{
    border: none;
}

/* video */
.play-control {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
#video-container {
	width: 100%;
	position: relative;
}
video {
    width: 100%;
}
.play {
    font-size: 35px;
}
.play, .mute {
    background-color: transparent;
    border: none;
    outline: none;
    color: #fff;
}
.mute {
    margin-right: 15px;
}
#play-pause {
    opacity: 0;
	-webkit-transition: opacity .3s;
	-moz-transition: opacity .3s;
	-o-transition: opacity .3s;
	-ms-transition: opacity .3s;
    transition: opacity .3s;
}
#video-controls {
    display: inline-flex;
	position: absolute;
	bottom: 5px;
	left: 0;
	right: 0;
	padding: 5px;
	opacity: 0;
	-webkit-transition: opacity .3s;
	-moz-transition: opacity .3s;
	-o-transition: opacity .3s;
	-ms-transition: opacity .3s;
    transition: opacity .3s;
    background: rgba(0,0,0,0.5);
}
#video-container:hover
#video-controls, #video-container:hover #play-pause {
	opacity: .9;
}
#seek-bar {
    margin-right: 15px;
    cursor: pointer;
    outline: none;
}
#volume-bar {
    width: 60px;
    cursor: pointer;
}
.main-box-video {
    width: 50%;
}
.text-under-label {
    color: #7a7a7a;
    font-weight: normal;
}

.table-review-promotion>tbody>tr>.th-target {
    padding-bottom: 15px;
}

/* popup box video  */
.box-modal-video {
    position: absolute;
    top: 40%;
    left: 29%;
    width: 42%;
}
.box-modal-list-img ul {
    list-style: none;
    text-align: left;
    padding: 0;
}
.box-modal-list-img ul li {
    padding-bottom: 5px;
}

.box-marketing-hd {
    padding: 20px 40px;
}
.box-marketing-bd {
    padding: 0 40px 20px 40px;
}
.btn-marketing-bottom-create {
    margin-top: 26px;
    margin-bottom: 16px;
    text-align: center;
}
.text-note {
    color: #adadad !important;
    font-style: italic;
    font-size: 11px;
    font-family: 'roboto-Regular';
}
.text-note-bd {
    color: #adadad !important;
    font-style: italic;
    font-size: 11px;
    font-family: 'roboto-Regular';
    margin-left: 5px;
}
.btn-new-city {
    position: relative;
    right: 1%;
}
.table-filer>tbody>tr>.td-label-title {
    vertical-align: middle;
}
.td-label-title label {
    margin-bottom: 0;
    padding-top: 5px;
    padding-bottom: 5px;
}

@media screen and (max-width: 1150px) {
    .nav-marketing-tab>li>a {
        padding: 12px 20px;
    }
}

.table-location>tbody>tr>td {
    border: none;
    padding: 0px 8px;
}
.table-location .search-label {
    text-align: right;
    padding-top: 5px;
}
.table-location .filter-label {
    padding-right: 0;
    padding-top: 5px;
}
.btn-search {
    background-color: #ffffff;
    border-right: 1px solid #d2d6de;
    border-top: 1px solid #d2d6de;
    border-bottom: 1px solid #d2d6de;
    border-left: none;
}
.btn-search:hover {
    background-color: #ffffff;
    background-color: #ffffff;
    border-right: 1px solid #d2d6de;
    border-top: 1px solid #d2d6de;
    border-bottom: 1px solid #d2d6de;
}
.btn-search svg {
    color: #6f6d6d;
}

.btn-search:active,
.btn-search:focus{
    background-color: #ffffff;
    border-color: #d2d6de;
}
.input-group-search>
.input-group-btn > .btn {
    outline: none;
}
.input-group-search .form-control {
    border-right-color: #ffffff;
}

.location-plasma {
    padding: 15px 20px 0;
}

.plasma .box-header {
    border-bottom: 1px solid #f4f4f4;
    padding-bottom: 0;
}

.nav-location-tab {
    background-color: #ffffff;
    border-radius: 5px;
}
.nav-location-tab>li>a {
    padding: 12px 40px;
    font-family: 'Roboto';
    border-top: none;
    color: #b4bac2;
    padding: 12px 30px;
}
.nav-location-tab>li>a:hover,
.nav-location-tab>li>a:active,
.nav-location-tab>li>a:focus {
    background-color: transparent;
    color: #e52d47;
}

.nav-location-tab>li.active>a,
.nav-location-tab>li.active>a:hover,
.nav-location-tab>li.active>a:focus {
    color: #e52d47;
    background-color: transparent;
    border-top-color: transparent;
    border-bottom: 3px solid #e52d47;
    font-weight: normal;
}

.table-plasma-content>tbody>tr>td {
    padding: 10px 8px;
    color: #545e67;
}

.btn-create-new {
    margin-top: 8px;
}

.h-action, .col-action {
    text-align: center;
}

.col-action img {
    margin-left: 6px;
    margin-right: 6px;
    width: 15px;
}

.modal-freeze-confirm .modal-content{
    border-radius: 5px;
}

.modal-freeze-confirm .confrim-txt{
    font-size: 18px;
    margin-top: 12%;
    margin-bottom: 12%;
}

.modal-freeze-confirm .section-btn-comfirm .btn-blue{
    font-size: 15px;
    width: 40%;
    margin: 0 auto;
}

.box-location {
    padding-top: 30px;
    padding-bottom: 30px;
}

.box-location .status-invalid {
    margin-left: 30px;
}

.box-location .row-lbl {
    margin-top: 7px;
    white-space: nowrap;
}

.btn-blue-custom {
    width: 210px;
    font-size: 14px;
}

.photo-wrp {
    padding-bottom: 10px;
}
.photo-wrp .col-md-3 {
    padding-top: 10px;
}
.location-btn {
    padding: 20px;
}
.location-btn .btn-submit {
    font-size: 14px;
    width: 230px;
}
.rm-img {
    position: absolute;
    padding-left: 3px;
    padding-right: 3px;
    cursor: pointer;
    right: 0;
    top: 5px;
}
.uploaded-img {
    float: left;
}
.photo-preview {
    margin-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
}

.photo-preview .img-wrp {
    float: left;
    width: 20%;
    position: relative;
    padding-right: 10px;
    padding-top: 10px;
}
.md-input {
    width: 15%;
    float: left;
}
.box-location .mid-dash {
    width: 15px;
    float: left;
    border-bottom: 1px solid #868686;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 16px;
}

.input-date {
    width: 40%;
    position: relative;
}

.input-date .icon-calendar {
    position: absolute;
    right: 10px;
    top: 8px;
    width: 16px;
}

.btn-add-wrp {
    width: 40%;
    position: relative;
    margin-top: 30px;
    margin-bottom: 30px;
}

.input-moc, .input-mob, .input-abp {
    width: 40%;
    position: relative;
    float:left;
    margin-right: 15px;
}
.span-moc, .span-mob, .span-abp {
    padding-top: 5px;
    float: left;
}

.box-row-1 {
    width: 43.5%;
    position: relative;
    margin-bottom: 15px;
}

.box-row-1 .icon-calendar {
    position: absolute;
    right: 25px;
    top: 8px;
    width: 16px;
}

.box-row-2 {
    width: 52%;
    margin-bottom: 15px;
}

.box-row-2 .sub-lbl{
    padding-left: 35px;
    padding-top: 5px;
    white-space: nowrap;
}

.pod-input input, .pod-input span {
    float: left;
}

.pod-input input {
    width: 50px;
    text-align: center;
    padding: 5px;
}

.pod-input span {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 5px;
}

.box-row-3 {
    margin-bottom: 15px;
    position: relative;
}
.mid-lbl {
    padding-top: 4px;
}

.mid-lbl label {
    font-family: 'roboto-Regular';
    white-space: nowrap;
}

.input-ait {
    width: 40%;
    float: left;
    position: relative;
}

.select-ait {
    width: 10%;
    float: left;
    position: relative;
    margin-left: 15px;
}

.select-ait option {
    color: #717171;
}

.dtr-wrp .ing-1 {
    width: 40%;
    float: left;
}

.title-page {
    font-size: 20px;
}

.dtr-wrp .input-group input {
    border-right: none;
}

.dtr-wrp .input-group .input-group-addon {
    border-left: none;
    border-radius: 0 4px 4px 0;
    color: #cccccc;
    font-size: 11px;
}

.dtr-wrp .ing-2 {
    width: 20%;
    margin-left: 15px;
    float: left;
}

.dtr-wrp .div-icon {
    width: 20px;
    float: left;
    margin-left: 15px;
    margin-top: 3px;
}
.div-icon img {
    width: 15px;
    cursor: pointer;
}

.dtr-wrp .btn-add-wrp {
    margin-bottom: 15px;
}

/* region style */
.vselected {
    padding: 6px 10px;
    border: 1px solid #009bdd;
    display: inline-block;
    border-radius: 4px;
}

.vselected .fa-times {
    margin-left: 15px;
    color: #009bdd;
    cursor: pointer;
}

/* The container */
.cb-container {
    display: block;
    position: relative;
    padding-left: 18px;
    margin-bottom: 20px;
    cursor: pointer;
    font-size: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.cb-container input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: #eaeaea;
    border-radius: 2px;
}

/* On mouse-over, add a grey background color */
.cb-container:hover input ~ .checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.cb-container input:checked ~ .checkmark {
    background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.cb-container input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.cb-container .checkmark:after {
    left: 5px;
    top: 2px;
    width: 5px;
    height: 9px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.region-btn-add .btn {
    width: 170px;
}

.lc-sl-placeholder {
    color: #989898;
}
.setting-container {
    padding-left: 40px;
}
input[type="radio"].csd-date {
    margin-top: 2px;
}
input[type="radio"].csd-period-date {
    margin-top: 2px;
}
.close {
    width: 22px;
    opacity: 10;
}
.close:hover {
    opacity: 10;
}
.content-confirm {
    font-size: 15px;
    width: 75%;
    margin: 0 auto;
}
.modal-header {
    border-bottom: 0;
    padding: 25px;
}
.modal-content {
    width: 90%;
}
.label-createPlasma {
    padding-left: 2.5em;
}
.phoneNumber-createPlasma {
    display: flex;
}
.phoneNumber-createPlasma input:first-child {
    width: 10%;
    margin-right: 1em;
}
