
*{margin:0;padding: 0;}

/* p { padding-bottom:50px;} */

.tree ul {
    margin-left: 20px;
}

.tree li {
    list-style-type: none;
    margin:10px;
    position: relative;
}

.tree li::before {
    content: "";
    position: absolute;
    top:-7px;
    left:-20px;
    border-left: 1px solid #ccc;
    border-bottom:1px solid #ccc;
    border-radius:0 0 0 0px;
    width:20px;
    height:15px;
}

.tree li::after {
    position:absolute;
    content:"";
    top:8px;
    left:-20px;
    border-left: 1px solid #ccc;
    border-top:1px solid #ccc;
    border-radius:0px 0 0 0;
    width:20px;
    height:100%;
}

.tree li:last-child::after  {
    display:none;
}

.tree li:last-child:before{
    border-radius: 0 0 0 5px;
}

ul.tree>li:first-child::before {
    display:none;
}

ul.tree>li:first-child::after {
    border-radius:5px 0 0 0;
}

.tree li a {
    border: 1px #ccc solid;
    border-radius: 5px;
    padding:2px 5px;
}

.tree li a:hover, .tree li a:hover+ul li a,
.tree li a:focus, .tree li a:focus+ul li a {
    background: #ccc; color: #000; border: 1px solid #000;
}

.tree li a:hover+ul li::after, .tree li a:focus+ul li::after,
.tree li a:hover+ul li::before, .tree li a:focus+ul li::before 
.tree li a:hover+ul::before, .tree li a:focus+ul::before 
.tree li a:hover+ul ul::before, .tree li a:focus+ul ul::before{
    border-color:  #000; /*connector color on hover*/
}

.label-cus {
    text-wrap: nowrap;
    position: absolute;
    margin-top: 1px;
    opacity: .65;
    transform: scale(.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-control-cus:focus {
    color: #212529;
    background-color: #fff;
    border-color: #86b7fe00;
    outline: 0;
    box-shadow: 0 0 0 1px rgba(13,110,253,.25);
}

.label-warp {
    text-wrap: nowrap;
}