.individual-schedule .rbc-calendar {
    height: 800px !important;
}

.appointment-window .rbc-calendar {
    height: 1380px !important;
}

.appointment-window .rbc-time-header, .individual-schedule .rbc-time-header {
    display: none !important;
}

.rbc-day-slot .rbc-time-slot {
    border-top: 1px solid #eeeeee !important;
}