@import 'bootstrap/dist/css/bootstrap.min.css';

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body{
  height: 100%; width: 100%; margin: 0; 
}

.patient-lab-result-table {
  border-style: hidden;
  border-top-style: unset;
  margin-bottom: 0;
}

.patient-lab-result-table>:not(caption)>*>* {
  padding: 0.25rem 1.25rem;
}

.icon-btn {
  color: lightslategray;
  cursor: pointer;
}

.icon-btn:hover {
  color: #007bff;
}

.action-column {
  background-color: rgba(222, 237, 255, 0.33);
}

.ant-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

a {
  text-decoration: none;
}

.anticon {
  display: inline-flex;
}

iframe#webpack-dev-server-client-overlay {
    display: none !important
}

.cursor-pointer {
  cursor: pointer;
}
.cursor-default {
  cursor: default;
}