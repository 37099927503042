.selected-language {
    padding: 6px 10px;
    border: 1px solid #009bdd;
    display: inline-block;
    border-radius: 4px;
    margin-right: 5px;
    margin-bottom: 5px;
}

.cancel-selected{
    margin-left: 15px;
    color: #009bdd;
    cursor: pointer;
    vertical-align: middle;
}