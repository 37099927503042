.case-search-fields {
  width: 100%;
  height: fit-content;
}

.case-create-button {
  text-wrap: nowrap;
  width: 100%;
}

.case-form label {
  font-weight: 600;
}

.link-disabled {
  cursor: not-allowed;
  border-color: #d9d9d9;
  color: rgba(0, 0, 0, 0.25);
  box-shadow: none;
}

.ant-select-disabled:where(.disabled-cursor-auto).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  cursor: auto !important;
}

:where(.form-items-center).ant-form-item .ant-form-item-row{
  align-items: center;
}

:where(.form-mb-none).ant-form-item {
  margin-bottom: 0 !important;
}

@media (min-width: 1400px) {
  .case-search-fields {
    width: 600px;
  }
}
@media (min-width: 1200px) {
}
@media (min-width: 992px) {
}
@media (min-width: 768px) {
  .case-create-button {
    width: fit-content;
  }
}
@media (min-width: 576px) {
}
