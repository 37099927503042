.form_input {
  width: 100%;
  height: 50px;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: 16px;
}

.button_auth {
  width: 100%;
  height: 60px;
  padding: 20px 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 30px;
  background-color: #3f86c7;
  transition: all 0.2s ease-in-out;
}

.button_auth_border {
  width: 100%;
  height: 60px;
  padding: 20px 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
    border: 1.5px solid black;
    border-radius: 30px;
    background-color: transparent;
    transition: all 0.2s ease-in-out;
}

.button_auth:hover {
    background-color: #30699b;
}

.button_auth:disabled {
    background-color: #dedede;
}

.link_auth {
  color:#30699b;
}

.link_auth:hover {
  text-transform: underline;
}

.auth_body {
    width: 600px;
    margin: auto;
}

.button_body {
    width: 364px;
}

@media (max-width: 767px) {
  .auth_body {
    width: 100%;
    margin: auto;
  }

  .button_body {
    width: 100%;
  }
}

@media (min-width: 821px) {

  .fontNormal {
    font-weight: 400 !important;
  }
}

.check_validate {
  width: 24px;
  height: 24px;
  background-color: #03b3be;
  border-radius: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.check_invalid {
  width: 24px;
  height: 24px;
  background-color: white;
  border: 1.2px solid black;
  border-radius: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.check_radio {
  width: 19px;
  height: 19px;
  accent-color: #000000;
}
