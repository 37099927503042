#search-form {
    margin-top: 1.5em;
    width: 50%;
}

#search-button {
    background-color: #fff;
    border: 1px solid #ced4da;
    border-left: 0;
    border-radius: 0 4px 4px 0;
}

.input-group {
    flex-wrap: nowrap;
}

.MuiAutocomplete-root {
    background-color: #fff;
}

.page-loader {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}