.content-wrapper-lc {
	margin-left: 0;
}
.content-wrapper-lc .content {
	font-size: 13px;
    font-family: 'roboto-Regular';
	padding: 9px 12px;
}
.skin-black .main-header-lc .navbar {
	background: #ffffff;
}
.skin-black .main-header-lc .navbar .navbar-nav>li>a {
	border-right: none;
}
.nav .postion-address a {
	top: 0;
}
.navbar-page-title-lc {
	padding-left: 9%;
}
.content-header-lc {
	padding-left: 0;
}

.btn-blue-lc {
    background-color: #009bdd;
    color: #ffff;
    border: none;
}

.btn-blue-lc:hover,
.btn-blue-lc:active,
.btn-blue-lc.hover {
    background-color: #009bdd;
    color: #ffff;
}

.box-lc.box-solid>.box-header .btn:hover, .box-lc.box-solid>.box-header .btn-blue-lc:hover {
    background-color: #009bdd;
    color: #ffff;
}

.title-wrp {
	padding-top: 20px;
	padding-left: 3px;
}

/* update 21-01-2020 */
@media screen and (max-width: 1024px) {
	.navbar-page-title-lc {
		padding-left: 3%;
    }
}