.new-appointment-page .react-datepicker {
    font-size: 1em;
    width: 100%;
}
.new-appointment-page .react-datepicker__header {
    padding-top: 0.8em;
    background: #5284C2;
}
.new-appointment-page .react-datepicker__month {
    margin: 0.4em 0.5em;
}
.new-appointment-page .react-datepicker__month-container {
    width: 100%;
}
.new-appointment-page .react-datepicker__day-name, .react-datepicker__day {
    width: 2.2em;
    line-height: 2.2em;
    margin: 0;
    color: black;
}

.new-appointment-page .react-datepicker__current-month {
    font-size: 1em;
    color: white;
}
.new-appointment-page .react-datepicker__navigation {
    top: 0.6em;
    line-height: 1.7em;
    border: 0.45em solid transparent;
}

.new-appointment-page .react-datepicker__navigation--previous {
    left: 1em;
}

.new-appointment-page .react-datepicker__navigation-icon::before {
    border-color: white;
}

.new-appointment-page .react-datepicker__navigation--next {
    right: 1em;
}

.new-appointment-page .react-datepicker__day--outside-month {
    color: lightgrey;
}

.new-appointment-page .react-datepicker__day {
    border:  rgba(82, 132, 194, 1%) solid 1px;
}

.new-appointment-page .react-datepicker__day:hover {
    border-radius: 0;
    border: black 1px dashed;
}

.new-appointment-page .react-datepicker__day:not(.react-datepicker__day--outside-month):not(.react-datepicker__day--disabled) {
    background: rgb(82, 132, 194);
    color: white;
}

.new-appointment-page .react-datepicker__day--selected {
    border: black solid 1px !important;
    box-shadow: inset .5px -.5px 0px .5px black, inset -.5px .5px 0px .5px black;
    border-radius: 0;
}

.new-appointment-page .react-datepicker__day--disabled:not(.react-datepicker__day--outside-month) {
    background: #EFF6FF;
    color: lightgrey;
}

.new-appointment-page .react-datepicker__day--disabled:hover {
    border-radius: 0;
    background: #EFF6FF !important;
    border: none !important;
}

.new-appointment-page .react-datepicker__day-name {
    color: white;
}