.procurement-order .ant-table-thead .ant-table-cell {
    font-weight: bolder;
    color:black !important;
}

.procurement-order .sort-input, .date-input {
    text-align: right;
}

.procurement-order .sort-label, .date-label {
    text-align: left;
}

.procurement-order .action-btn {
    margin:10px;
}

.procurement-order .ant-input-suffix > *:not(:last-child) {
    margin-right: 20px !important;
}

.procurement-order .upload {
    padding-top: 14px;
}