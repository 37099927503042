/* Tooltip container */
.tooltip-bottom {
    position: relative;
    display: inline-block;
    margin-left: 5px;
}
  
  /* Tooltip text */
.tooltip-bottom .tooltiptext {
    visibility: hidden;
    background-color: rgba(0, 0, 0, 0.6);
    color: #fff;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    padding-top: 5px;
    border-radius: 10px;
    width: 40rem;
    top: 100%;
    left: 50%;
    margin-left: -20rem;
    position: absolute;
    z-index: 1000;
    display: inline-block;
    margin-top: 5px;
}
  
/* Show the tooltip text when you mouse over the tooltip container */
.tooltip-bottom:hover .tooltiptext {
    visibility: visible;
}

.tooltip-bottom .tooltiptext::after{
    content: " ";
    position: absolute;
    bottom: 100%;  /* At the top of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent rgba(0, 0, 0, 0.6) transparent;
}