.App {
  height: 100%; width: 100%;
  min-height: calc(100vh - 60px);

  display: flex;
  flex-direction: row;
}

.sla-gray .ant-table-cell {
  background-color: #f0f0f0 !important;
}

.sla-red .ant-table-cell {
  background-color: #FFE9E7 !important;
}

.sla-green .ant-table-cell {
  background-color: #EEFFEC !important;
}

.sla-yellow .ant-table-cell {
  background-color: #ffffd9 !important;
}

.required-star{
  font-weight: 700;
  color: red;
}
/* 
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */


.layout{
  height: 100%;
  overflow: scroll;
}


.dasboard-card-container{
  width: 100%;
  height: 100%;
  background-color: white;
  box-shadow: 0 1.6rem 3rem rgb(0 0 0 / 10%);
  padding: 18px;
  border-color:rgb(0 0 0 / 10%);
  border-style: solid;
  border-width: 1px;
  border-radius: 9px;
}

.dashboard-card-title{
  font-weight: 600;
}

.withgraph{
  padding: 12px;
}

.dashboard-card-subtitle{
  font-size: 0.9rem!important;
  font-weight: 500;
  color: #595959;
}

.dashboard-number{
  font-size: 1.25rem!important;
  font-weight: 600;
}

.dashboard-description{
  font-size: 0.75rem!important;
}

.dashboard-number-text{
  font-size: 1.5rem!important;
  font-weight: 700;
}

.section-title{
  font-weight: 600;
  font-size: 1.2rem;
  text-align: center;
}

.pos-abs{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999!important;
}

.cta-1-btn{
  font-weight: 700;
  color: blue;
  font-size: 0.9rem;
  align-items: center;
  cursor: pointer;
}


.border-seperator{
  height: 1px;
  width: 90%;
  margin-right: 5%;
  margin-left: 5%;
  margin-top: 24px;
  margin-bottom: 24px;
  background-color: gray;
  align-self: center;
  justify-self: center;
}

.dropdown-disabled{
  background-color: red;
}

.modal-80w {
  min-width:80%;
}

.not-confirmed-event {
  opacity: 0.8;
}

.green-event {
  border: none !important;
  color: #ffffff !important;
  padding-left: 8px !important;
  background: #06b200 !important;
}
.not-confirmed-event.green-event::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 6px;
  background: repeating-linear-gradient(
          -35deg,
          #06b200,
          #06b200 2px,
          #beeebd 2px,
          #beeebd 4px
  );
}
.confirmed-event.green-event {
  border-left: 6px solid #5bc002 !important;
}

.light-green-event {
  border: none !important;
  color: #0B5A08 !important;
  padding-left: 8px !important;
  background: #D3F1D2 !important;
}
.not-confirmed-event.light-green-event::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 6px;
  background: repeating-linear-gradient(
          -35deg,
          #0a9d7c,
          #0a9d7c 2px,
          #D3F1D2 2px,
          #D3F1D2 4px
  );
}
.confirmed-event.light-green-event {
  border-left: 6px solid #0a9d7c !important;
}

.blue-event {
  border: none !important;
  color: #ffffff !important;
  padding-left: 8px !important;
  background: #1672f6 !important;
}
.not-confirmed-event.blue-event::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 6px;
  background: repeating-linear-gradient(
          -35deg,
          #1672f6,
          #1672f6 2px,
          #D0E7F8 2px,
          #D0E7F8 4px
  );
}
.confirmed-event.blue-event {
  border-left: 6px solid #2b98e7 !important;
}

.light-blue-event {
  border: none !important;
  color: #004377 !important;
  padding-left: 8px !important;
  background: #D0E7F8 !important;
}
.not-confirmed-event.light-blue-event::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 6px;
  background: repeating-linear-gradient(
          -35deg,
          #0078D4,
          #0078D4 2px,
          #D0E7F8 2px,
          #D0E7F8 4px
  );
}
.confirmed-event.light-blue-event {
  border-left: 6px solid #0078D4 !important;
}

.red-event {
  border: none !important;
  color: #fff4f3 !important;
  padding-left: 8px !important;
  background: #ff1800 !important;
}
.not-confirmed-event.red-event::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 6px;
  background: repeating-linear-gradient(
          -35deg,
          #ff1800,
          #ff1800 2px,
          #FADBD8 2px,
          #FADBD8 4px
  );
}
.confirmed-event.red-event {
  border-left: 6px solid #bd2618 !important;
}

.orange-event {
  border: none !important;
  color: #653600 !important;
  padding-left: 8px !important;
  background: #FFA500 !important;
}
.not-confirmed-event.orange-event::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 6px;
  background: repeating-linear-gradient(
          -35deg,
          #cc8400,
          #cc8400 2px,
          #FEE5D7 2px,
          #FEE5D7 4px
  );
}
.confirmed-event.orange-event {
  border-left: 6px solid #e59400 !important;
}

.gold-event {
  border: none !important;
  color: #332b00 !important;
  padding-left: 8px !important;
  background: #FFD700 !important;
}
.not-confirmed-event.gold-event::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 6px;
  background: repeating-linear-gradient(
          -35deg,
          #998100,
          #998100 2px,
          #FFD700 2px,
          #FFD700 4px
  );
}
.confirmed-event.gold-event {
  border-left: 6px solid #998100 !important;
}

.yellow-event {
  border: none !important;
  color: #191900 !important;
  padding-left: 8px !important;
  background: #FFFF00 !important;
}
.not-confirmed-event.yellow-event::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 6px;
  background: repeating-linear-gradient(
          -35deg,
          #fdca00,
          #fdca00 2px,
          #FFFF00 2px,
          #FFFF00 4px
  );
}
.confirmed-event.yellow-event {
  border-left: 6px solid #fdca00 !important;
}

.pink-event {
  border: none !important;
  color: #000000 !important;
  padding-left: 8px !important;
  background: #FFC0CB !important;
}
.not-confirmed-event.pink-event::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 6px;
  background: repeating-linear-gradient(
          -35deg,
          #FF1493,
          #FF1493 2px,
          #FFC0CB 2px,
          #FFC0CB 4px
  );
}
.confirmed-event.pink-event {
  border-left: 6px solid #FF1493 !important;
}

.lavender-event {
  border: none !important;
  color: #5c62ad !important; /* Adjust this to the desired text color */
  padding-left: 8px !important;
  background: #E6E6FA !important; /* Adjust this to the desired background color */
}
.not-confirmed-event.lavender-event::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 6px;
  background: repeating-linear-gradient(
          -35deg,
          #5c62ad,
          #5c62ad 2px,
          #E6E6FA 2px,
          #E6E6FA 4px
  );
}
.confirmed-event.lavender-event {
  border-left: 6px solid #5c62ad !important;
}

.purple-event {
  border: none !important;
  color: #f2e5f2 !important; /* Adjust this to the desired text color */
  padding-left: 8px !important;
  background: #800080 !important; /* Adjust this to the desired background color */
}
.not-confirmed-event.purple-event::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 6px;
  background: repeating-linear-gradient(
          -35deg,
          #800080,
          #800080 2px,
          #cc99cc 2px,
          #cc99cc 4px
  );
}
.confirmed-event.purple-event {
  border-left: 6px solid #590059 !important;
}

.brown-event {
  border: none !important;
  color: #ffffff !important; /* Adjust this to the desired text color */
  padding-left: 8px !important;
  background: #6D4C41 !important; /* Adjust this to the desired background color */
}
.not-confirmed-event.brown-event::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 6px;
  background: repeating-linear-gradient(
          -35deg,
          #6D4C41,
          #6D4C41 2px,
          #D7CCC8 2px,
          #D7CCC8 4px
  );
}
.confirmed-event.brown-event {
  border-left: 6px solid #969290 !important;
}

.steelblue-event { /* this class is used to represent other existing appointment when making/ modifying appointment */
  border: 1px dodgerblue solid !important;
  color: white !important;
  border-left: 6px solid lightsteelblue !important;
  background: steelblue !important;
}

.reschedule-requested>.rbc-event-content::after {
  position: absolute !important;
  content: '🙋' !important;
  font-size: 18px !important;
  top: 2px !important;
  right: 2px !important;
}

.canceled-event::after {
  position: absolute !important;
  color: #FC2947;
  content: 'Ⓧ' !important;
  font-size: 18px !important;
  bottom: -2px !important;
  right: 4px !important;
}

.rescheduled-event::after {
  position: absolute !important;
  color: #F49D1A;
  content: "\21AC" !important;
  font-size: 28px !important;
  bottom: -10px !important;
  right: 4px !important;
}

.no-show-event::after {
  position: absolute !important;
  color: #dc5d40;
  content: 'Ø' !important;
  font-size: 18px !important;
  bottom: -2px !important;
  right: 4px !important;
}

.completed-event::after {
  position: absolute !important;
  color: #10790e;
  content: '𝍎' !important;
  font-size: 18px !important;
  bottom: 2px !important;
  right: 4px !important;
}

.pending-event {
  opacity: .8;
  border: 2px solid #59C1BD !important;
  background-image: linear-gradient(
          45deg, #DEF5E5 25%, #BCEAD5 25%, #BCEAD5 50%, #DEF5E5 50%, #DEF5E5 75%, #BCEAD5 75%, #BCEAD5 100%
  ) !important;
  background-size: 40px 40px !important;
  color: #0D4C92 !important;
  left: 5% !important;
  width: 95% !important;
  z-index: 999;
}

.error-event {
  opacity: .9;
  border: 2px solid #cc2c2c !important;
  background-image: linear-gradient(
          45deg, #ff7070 25%, #ff3838 25%, #ff3838 50%, #ff7070 50%, #ff7070 75%, #ff3838 75%, #ff3838 100%
  ) !important;
  background-size: 40px 40px !important;
  color: white !important;
  left: 5% !important;
  width: 95% !important;
  z-index: 999;
}

.blocked-event {
  opacity: .9;
  border: 2px solid #222831 !important;
  background-image: linear-gradient(
          45deg, #444444 25%, #171717 25%, #171717 50%, #444444 50%, #444444 75%, #171717 75%, #171717 100%
  ) !important;
  background-size: 40px 40px !important;
  color: white !important;
  width: 104% !important;
}

.marging-cus {
  margin-top: 0px !important;
  /* padding: 32px; */
  min-height: 100vh;
}
.site-layout-background-cus {
  background: #F0F2F5 0% 0% no-repeat padding-box;
}
.site-layout-background {
  background: #F0F2F5 0% 0% no-repeat padding-box;
}

.cus-hover:hover {
  color: #00c0ef;
  background: transparent;
  border: 1px solid #00c0ef;
}

.err-border {
  border: 0.075rem solid rgb(237 28 12 / 93%) !important
}

.ant-table-filter-column {
  justify-content: start !important;
}

.ant-table-column-title {
  flex: unset !important;
}

div.side-nav-section:not(:has(div.nav-button)) {
  display: none
}

.chatBox p {
  margin-bottom: 8px;
}

.message-sending .ant-input:focus {
  box-shadow: none !important;
}

.message-sending:focus-within {
  box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.2) !important;
}

.message-sending.error:focus-within {
  box-shadow: 0 0 0 2px rgba(237, 28, 12, .5) !important;
}

.message-sending .ant-upload-list-item {
  margin: 2px 8px 8px !important;
}

.message-sending .attach-image-button .ant-upload-list {
  display: none !important;
}

.message-sending.error {
  animation: shake 0.5s ease-in-out;
}

@keyframes shake {
  0%, 100% {
    transform: translateX(0);
  }
  25%, 75% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
}

.chatBox .message-display-entry {
  white-space: pre-line;
  position: relative;
  margin-block: 0;
  padding: 4px 8px;
  margin-left: -8px;
}

.chatBox .message-display-entry.with-time:hover:before {
  content: attr(data-entry-time);
  position: absolute;
  left: -54px;
  opacity: 0.4;
  font-size: 12px;
  top: 7px;
}

.chatBox .message-display-entry:hover {
  background: #EEEEEE;
  border-radius: 8px;
}

.chatBox .ant-space .ant-space-item:nth-child(even) {
  width: 100%;
}

.chatBox .ant-collapse-header {
  padding-inline: 0 !important;
  padding-block: 2px !important;
  font-size: 12px;
  font-weight: bold;
  opacity: 0.4;
  align-items: center !important;
}

.chatBox .ant-collapse-content-box {
  padding-inline: 2px !important;
  padding-block: 2px !important;
}

.message-board .Mui-selected {
  color: #1677ff !important;
}

.treatment-select .ant-select-selector{
  min-height: 30px;
}
.p-relative {
    position: relative;
}