.icon-hover {
    cursor: pointer;
}

.icon-hover.ok:hover {
    color: #8FDC97;
}

.icon-hover.cancel:hover {
    color: #9F4A54;
}

.input-label {
    flex: 1;
}

.edit-icon {
    width: 14px;
}

.setting-input-multiselect {
    width: 100%;
}