.nav-sider{
  background-color: #05050b;
  height: calc(100vh - 60px);
  overflow-x: hidden;
  overflow-y: auto;
}

.biostation-nav-logo{
  width: 135px;
}

.nav-seperate{
  height: 1px;
  width: 100%;
  background-color: rgba(45,45,50);
}

.nav-title {
  color: white;
  font-size: 12px;
  margin-left: 8px;
  font-weight: 600;
}

.nav-mini{
  color: rgba(231,238,248,.6);
  font-size: 11px;
  font-weight: 600;
}


.nav-button{
  cursor: pointer;
}

.nav-button:hover{
  background-color: rgba(25,25,32);
}

.nav-active{
  background-color: rgba(25,25,32);
}