/* tab marketing */
.nav-marketing-tab {
    background-color: #ffffff;
    border-radius: 5px;
}
.nav-marketing-tab>li>a {
    padding: 12px 40px;
    font-family: 'Roboto';
    border-top: none;
    color: #b4bac2;
}
.nav-pills>li.active>a {
    font-weight: normal;
}
.nav-marketing-tab>li.active>a,
.nav-marketing-tab>li.active>a:hover,
.nav-marketing-tab>li.active>a:focus {
    color: #e52d47;
    background-color: transparent;
    border-top-color: transparent;
    border-bottom: 3px solid #e52d47;
}
.nav-marketing-tab>li>a:hover,
.nav-marketing-tab>li>a:active,
.nav-marketing-tab>li>a:focus {
    color: #e52d47;
    border-bottom: none;
    background-color: #ffffff;
}
.nav-marketing-tab > li + li {
    margin-left: 0;
}
.btn-new-survey {
    margin-top: 3px;
}
/* Your Surveys */
.table-marketing>tbody>tr>th {
    padding: 10px 8px;
    border-top: none;
}
.table-marketing>tbody>tr>td {
    padding: 10px 8px;
    vertical-align: middle;
    color: #545e67;
}
.icon-sign-red {
    color: #e52d47;
    font-size: 8px;
    vertical-align: middle;
}
.icon-edit {
    width: 15px;
}
.icon-sign-waiting {
    color: #f7be45;
    font-size: 8px;
    vertical-align: middle;
}
.table-filer>tbody>tr>td {
    vertical-align: sub;
    text-align: right;
    border: none;
}
.table-filer>tbody>tr>td:first-child {
    text-align: left;
}
.status-reviewed span {
    font-size: 18px;
}
.icon-sign-waiting-publish {
    color: #0ecea8;
    font-size: 9px;
    vertical-align: middle;
}
.space-td-action {
    width: 8%;
}
.icon-view {
    width: 15px;
    vertical-align: -webkit-baseline-middle;
}
/* pop up phone */
.main-list-style {
    padding-left: 12px;
}
.sub-list-style {
    list-style: none;
    padding: 0;
}
.box-modal-text {
    position: absolute;
    top: 39%;
    left: 50%;
    width: 42%;
    transform: translate(-50%, -50%);
    text-align: left;
}
.logo-text {
    position: absolute;
    left: 65px;
}
.logo-text p {
    margin: 0;
    color: #7a7a7a;
}
.logo-text p:first-child {
    font-size: 14px;
    color: #06c;
}
.box-logo {
    margin-left: 0;
}
.modal-content-phone {
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color: transparent;
}
.icon-close {
    cursor: pointer;
}
/* page create promotion news */
.table-news-form>tbody>tr>td {
    border: none;
}
.table-news-form>tbody>tr>td:nth-of-type(1) {
    width: 20%;
    padding-left: 0;
}
.width-input-box {
    width: 65%;
}
.box-marketing-bd-form {
    padding-left: 40px;
    padding-right: 40px;
}
.table-feedback>tbody>tr>td {
    border: none;
}
.table-feedback>tbody>tr>.feedback-label {
    width: 20%;
    vertical-align: middle;
    padding-left: 0;
}
.td-width {
    width: 20%;
    padding-left: 0 !important;
}
.label-middle {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    padding-left: 0 !important;
}
.table-feedback>tbody>tr>td>.radio {
    margin-top: 0;
    margin-bottom: 0;
}
.table-feedback>tbody>tr>.feedback-yes-radio {
    padding-top: 0;
}
.table-feedback>tbody>tr>.feedback-no-radio {
    padding-bottom: 0;
}
.num-order-list {
    color: #e52d47;
    border: 1px solid #e52d47;
    padding-left: 7px;
    padding-right: 7px;
    border-radius: 50px;
    padding-top: 1px;
    margin-right: 10px;
    padding-bottom: 1px;
}
.num-order-with-label {
    position: relative;
    right: 36px;
}
.select-btn-add-qt {
    width: 60%;
    text-align: initial;
    margin-left: 5%;
    text-align: center;
    margin-top: 20px;
}
.label-title span {
    color: #e52d47;
    vertical-align: sub;
}
.box-header-promotion {
    padding: 10px 20px;
}
.table-news-promote>tbody>tr>td {
    font-family: 'Roboto';
    font-size: 12px;
    border: none;
}
.table-news-promote>tbody>tr>td>span:first-child {
    color: #e52d47;
    vertical-align: sub;
}
.table-news-promote>tbody>tr>td:nth-of-type(1) {
    width: 20%;
    padding-left: 0;
}
.table-news-promote>tbody>tr>td:nth-of-type(2) {
    width: 9%;
}
.table-news-promote>tbody>tr>td:nth-of-type(3) {
    width: 16%;
}
.promote-checkbox {
    margin-top: 0;
    margin-bottom: 0;
}
.promote-checkbox label {
    font-family: 'Roboto';
    font-size: 12px;
}
.promote-checkbox, input[type="checkbox"] {
    margin: 2px 0 0;
}
.promote-text-note {
    color: #7a7a7a;
    font-weight: normal;
}
.icon-sign-required {
    color: #e52d47;
    vertical-align: sub;
}
.table-target-audience>tbody>tr>td {
    font-size: 12px;
    border: none;
    font-family: 'Roboto';
}
.table-target-audience>tbody>tr>td:nth-of-type(1) {
    width: 20%;
    padding-left: 0;
}
.table-target-audience>tbody>tr>.td-location {
    padding: 15px 8px;
}
.table-target-audience>tbody>tr>.td-city-selection {
    font-weight: normal;
    font-family: 'roboto-Regular';
}
.table-target-audience>tbody>tr>.td-padding {
    padding-bottom: 25px;
}
.box-btn-lg {
    width: 70%;
    text-align: center;
    margin: 0 auto;
    position: relative;
    right: 5%;
    margin-bottom: 20px;
    margin-top: 20px;
}
.box-btn-lg .btn-blue {
    width: 25%;
    padding-top: 9px;
    padding-bottom: 9px;}
.box-btn-lg .btn-submit {
    padding-top: 9px;
    padding-bottom: 9px;
    width: 53%;
}
.box-btn-lg a:nth-of-type(1) {
    margin-right: 10px;
}
.box-btn-lg a:nth-of-type(2) {
    margin-left: 10px;
}
/* campaigns page */
.add-list-city {
    counter-reset: city-counter;
    list-style: none;
}
.add-list-city li {
    margin-bottom: 10px;
}
.add-list-city li::before {
    content: counter(city-counter) ". ";
    counter-increment: city-counter;
    line-height: var(--size);
    text-align: center;
    position: absolute;
    margin-top: 10px;
    left: 30px;
}
.icon-trash {
    width: 17px;
    cursor: pointer;
}
.btn-submit {
    padding: 8px 50px;
}
.list-input-group .input-group-addon {
    border: none;
}
.location-city select {
    width: 64%;
}
.location-state .state {
    display: inline-block;
    width: 30%;
    margin-right: 30px;
}
.cash,
.point {
    display: inline-block;
    width: 58px;
    text-align: center;
}
.radio {
    margin-bottom: 20px;
}
.radio-status-phone {
    margin-bottom: 10px;
}
.table-rewards>tbody>tr>td {
    border: none;
}
.table-rewards>tbody>tr>td:first-child {
    padding-left: 0;
}
.table-rewards .checkbox {
    margin-top: 6px;
}
.label-text-rigth  {
    font-weight: normal;
    vertical-align: sub;
}
.reward-text-box {
    width: 30%;
}
.label-acv {
    vertical-align: middle;
}
/* modal publish survey and promotion new confirm */
.modal-survey-confirm .modal-content,
.modal-news-confirm .modal-content {
    border-radius: 5px;
    padding: 15px;
}
.modal-header {
    border-bottom: none;
}
.modal-survey-confirm .confrim-txt,
.modal-news-confirm .confrim-txt {
    font-size: 17px;
    padding-bottom: 49px;
    padding-top: 20px;
}
.modal-survey-confirm .section-btn-comfirm .btn-blue,
.modal-news-confirm .section-btn-comfirm .btn-blue {
    font-size: 16px;
}
/* review promotion */
.table-review-promotion>tbody>tr>th {
    padding-left: 0;
}
.table-review-promotion>tbody>tr>th:nth-last-of-type(1) {
    width: 20%;
}
.table-review-promotion>tbody>tr>td,
.table-review-promotion>tbody>tr>th{
    border: none;
}
/* video */
.play-control {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
#video-container {
	width: 100%;
	position: relative;
}
video {
    width: 100%;
}
.play {
    font-size: 35px;
}
.play, .mute {
    background-color: transparent;
    border: none;
    outline: none;
    color: #fff;
}
.mute {
    margin-right: 15px;
}
#play-pause {
    opacity: 0;
	-webkit-transition: opacity .3s;
	-moz-transition: opacity .3s;
	-o-transition: opacity .3s;
	-ms-transition: opacity .3s;
    transition: opacity .3s;
}
#video-controls {
    display: inline-flex;
	position: absolute;
	bottom: 5px;
	left: 0;
	right: 0;
	padding: 5px;
	opacity: 0;
	-webkit-transition: opacity .3s;
	-moz-transition: opacity .3s;
	-o-transition: opacity .3s;
	-ms-transition: opacity .3s;
    transition: opacity .3s;
    background: rgba(0,0,0,0.5);
}
#video-container:hover
#video-controls, #video-container:hover #play-pause {
	opacity: .9;
}
#seek-bar {
    margin-right: 15px;
    cursor: pointer;
    outline: none;
}
#volume-bar {
    width: 60px;
    cursor: pointer;
}
.main-box-video {
    width: 50%;
}
.text-under-label {
    color: #7a7a7a;
    font-weight: normal;
}
.table-review-promotion>tbody>tr>.th-target {
    padding-bottom: 15px;
}
/* popup box video  */
.box-modal-video {
    position: absolute;
    top: 40%;
    left: 29%;
    width: 42%;
}
.box-modal-list-img ul {
    list-style: none;
    text-align: left;
    padding: 0;
}
.box-modal-list-img ul li {
    padding-bottom: 5px;
}
.box-marketing-hd {
    padding: 20px 40px;
}
.box-marketing-bd {
    padding: 0 40px 20px 40px;
}
.btn-marketing-bottom-create {
    margin-top: 26px;
    margin-bottom: 16px;
    text-align: center;
}
.text-note {
    color: #adadad !important;
    font-style: italic;
    font-size: 11px;
    font-family: 'roboto-Regular';
}
.text-note-bd {
    color: #adadad !important;
    font-style: italic;
    font-size: 11px;
    font-family: 'roboto-Regular';
    margin-left: 5px;
}
.btn-new-city {
    position: relative;
    right: 1%;
}
.table-filer>tbody>tr>.td-label-title {
    vertical-align: middle;
}
.td-label-title label {
    margin-bottom: 0;
    padding-top: 5px;
    padding-bottom: 5px;
}
@media screen and (max-width: 1150px) {
    .nav-marketing-tab>li>a {
        padding: 12px 20px;
    }
}

.user-icon {
    width: 60px;
    float: left;
    margin-right: 10px;
}

.user-icon .user-image {
    width: 60px;
    border-radius: 50px;
}

.user-info {
    margin-top: 10px;
}

.user-info .fb-date {
    color: #c3c3c3;
}

.user-name {
    font-weight: 550;
}

.feedback-content {
    padding-top: 15px;
    padding-bottom: 10px;
    line-height: 1.75;
}

.row-wrp {
    padding-top: 20px;
    padding-bottom: 20px;
}

.view-detial {
    margin-top: 10px;
    font-weight: 550;
}

.feedback-list {
    margin-top: 20px;
}

.box-create-survey,
.box-create-feedback,
.box-survey-av,
.box-target-audience,
.box-reward {
    padding: 20px;
}

.box-create-survey .objective,
.box-create-survey .question {
    width: 58%;
}
.text-gray {
    color: #7a7a7a;
}
.box-create-survey .text-gray,
.box-create-feedback .text-gray,
.box-survey-av .text-gray,
.box-target-audience .text-gray {
    margin-left: 10px;
    color: #7a7a7a;
}

.box-create-survey .rate-desc {
    font-weight: 550;
}

.box-create-survey .num-order,
.box-approval-wrp .num-order,
.box-review-wrp .num-order {
    color: #e52d47;
    border: 1px solid #e52d47;
    padding-left: 7px;
    padding-right: 7px;
    border-radius: 50px;
    padding-top: 3px;
    margin-right: 10px;
    padding-bottom: 3px;
    margin-left: -4px;
}

.box-create-survey .rate-list {
    margin-left: 25px;
}

.rate-list li {
    padding: 5px;
}

.form-2 {
    padding-left: 0;
}

.input-q {
    width: 73%;
}

.input-q .input-addon {
    padding: 0 5px 0 0;
    border: none;
    font-weight: 550;
}

.input-q .form-control:not(:first-child):not(:last-child),
.input-answer .form-control:first-child,
.select-city .form-control:first-child {
    border-radius: 3px;
}

.input-addon-trash {
    width: 17px;
    margin-left: 15px;
    cursor: pointer;
}

.input-answer {
    margin-left: 25px;
    margin-top: 15px;
    width: 60%;
}

.input-answer .input-addon,
.select-city .input-addon {
    border: none;
}

.input-addon-plus, .sub-addon-trash {
    margin-left: 5px;
    width: 17px;
    cursor: pointer;
}

.answer-ol {
  counter-reset: answer-counter;
  list-style: none;
  margin-left: 0;
  padding-left: 20px;
}

.answer-ol li {
  counter-increment: answer-counter;
  position: relative;
}

.answer-ol li::before {
    content: counter(answer-counter) ". ";
    line-height: var(--size);
    text-align: center;
    position: absolute;
    top: 5px;
}

.city-ol {
  counter-reset: city-counter;
  list-style: none;
  margin-left: -15px;
}

.city-ol li {
  counter-increment: city-counter;
  position: relative;
}

.city-ol li::before {
    content: counter(city-counter) ". ";
    line-height: var(--size);
    text-align: center;
    position: absolute;
    top: 5px;
}

.select-city {
    margin-left: 25px;
    margin-top: 15px;
    width: 61%;
}

.section-btn {
    margin-top: 20px;
}

.btn-blue-custom {
    background: #FFFFFF;
    color: #009bdd;
    border: 1px solid #009bdd;
    width: 210px;
    letter-spacing: 0.4px;
}

.btn-blue-custom:hover, .btn-blue-custom:focus {
    color: #0379ab;
    border: 2px solid #0379ab;
}

.box-create-feedback {
    padding-bottom: 0;
}

.box-create-feedback .radio {
    margin-top: 0;
    margin-bottom: 25px;
}
.valid-for {margin-bottom: 0;}
.valid-for input[type="text"],
.valid-for select {
    display: inline-block;
    margin-left: 15px;
}

.valid-for input[type="text"] {
    width: 50px;
    padding: 6px;
    text-align: center;
    margin-left: 35px;
}

.valid-for select {
    width: 250px;
}

.valid-yes input[type="text"] {
    width: 40px;
    margin-left: 5px;
    margin-right: 5px;
    padding: 6px;
    display: inline-block;
    text-align: center
}

.valid-yes .year {
    width: 55px !important;
}

.valid-yes .day {
    margin-left: 60px !important;
}

.avaliable-in .mg-checkbox,
.checkbox-wrp .mg-checkbox {
    margin-left: 40px;
}

.checkbox-wrp .custom-age {
    margin-left: 48px;
}

.custom-age .age-from,
.custom-age .age-to {
    width: 50px;
    display: inline-block;
    padding: 6px;
    height: 25px;
    font-size: 12px;
}

.box-target-audience .gender .checkbox-inline,
.box-target-audience .age-group .checkbox-inline,
.box-target-audience .activeness .checkbox-wrp {
    margin-bottom: 15px;
}

.box-target-audience .location-city select {
    width: 64%;
}

.box-target-audience .location-state .state {
    display: inline-block;
    width: 30%;
    margin-right: 30px;
}

.box-target-audience .section-btn {
    margin-top: 10px;
}

.content small.text-gray {
    margin-left: 0;
}

.box-reward {
    padding-bottom: 15px;
}

.box-reward .cash,
.box-reward .point {
    display: inline-block;
    width: 58px;
    text-align: center;
}

.box-reward .radio{
    margin-bottom: 20px;
}

.btn-draft,
.btn-preview,
.btn-reject,
.btn-unpublish {
    padding-top: 10px;
    padding-bottom: 10px;
    margin-right: 20px;
    margin-top: 20px;
    width: 200px;
    font-size: 14px;
}

.btn-submit-approval,
.btn-submit-reviewed,
.btn-submit-approve {
    padding-top: 10px;
    padding-bottom: 10px;
    width: 428px;
    margin-right: 20px;
    margin-top: 20px;
    margin-bottom: 30px;
    font-size: 14px;
}

.content .mg-txt-gray {
    margin-left: 20px;
}

.box-review-wrp, .box-approval-wrp {
    padding: 20px 40px;
}

.review-survey-box-title {
    font-size: 18px;
    margin-top: 0;
    color: #000;
}

.box-review-wrp .head-wrp,
.box-approval-wrp .head-wrp {
    margin-bottom: 25px;
}

.box-review-wrp .hasNote,
.box-approval-wrp .hasNote {
    margin-bottom: 0;
}

.box-review-wrp .form-group,
.box-approval-wrp .form-group {
    margin-bottom: 20px;
}

/* progress bar */
.progress-wrp {
    position: relative; height: 70px;
}

.progress-icon {
    position: absolute;
    top: 17px;
    z-index: 1;
}

.progress-wrp .padr2 {
    padding-right: 2%;
}
.progress-wrp .padr3 {
    padding-right: 3%;
}
.progress-wrp .padr4 {
    padding-right: 4%;
}
.progress-wrp .padr5 {
    padding-right: 5%;
}
.progress-wrp .padr6 {
    padding-right: 6%;
}
.progress-wrp .padl3 {
    padding-left: 3%;
}
.progress-wrp .padl4 {
    padding-left: 4%;
}
.progress-wrp .mgl4-7 {
    margin-left: 4.7%;
}
.progress-wrp .progress-ct {
    margin-bottom: 5px;
    margin-top: 5px;
    background: #fefefe;
    border-radius: 4px;
}

.rate-list .pgress-wrp {
    float: left;
    width: 80%;
}

.rate-list .pgress-wrp .progress {
    float: left;
    margin-top: 6px;
    margin-bottom: 0px;
    border-radius: 0 5px 5px 0;
}

.rate-list .pgress-wrp .progress-bar {
    width: 100%
}

.rate-list .pgress-wrp .pgress-percent {
    float: left;
    margin-left: 10px;
}

.rate-list .rcontent {
    width: 13%;
    float: left;
}

.rate-list .col-md-12 {
    padding-left: 0;
}

.progress-color-1 {
    color: #d7d9dd;
}
.progress-bgcolor-1 {
    background: #d7d9dd;
}
.progress-color-2 {
    color: #7d9cae;
}
.progress-bgcolor-2 {
    background: #7d9cae;
}
.progress-color-3  {
    color: #5d7593;
}
.progress-bgcolor-3  {
    background: #5d7593;
}
.progress-color-4  {
    color: #f5a56b;
}
.progress-bgcolor-4  {
    background: #f5a56b;
}
.progress-color-5  {
    color: #f5826b;
}
.progress-bgcolor-5  {
    background: #f5826b;
}
.feedback-no {
    vertical-align: sub;
}
.rewards-option {
    margin-bottom: 10px;
    margin-top: 0;
}

.box-general-report {
    line-height: 35px;
    font-size: 15px;
    padding-left: 8px;
}

.box-target-statistics {
    font-weight: bold;
}

.tot-stat-val div span {
    float: right;
    margin-right: 50%;
}

/*  arrow up */
span.arr-up {
    color: #09CE4F;
    font-weight: bold;
}

 /* arrow down */
span.arr-down {
    color: #FFC577;
    font-weight: bold;
}

span.chart-wh-rep-caption {
    color: #ddd;
    font-size: 85%;
}

.arrow_box:after, .arrow_box:before {
	top: 100%;
	left: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

.arrow_box:after {
	border-color: rgba(255, 255, 255, 0);
	border-top-color: #ffffff;
	border-width: 9px;
	margin-left: -10px;
}

.arrow_box:before {
	border-color: rgba(221, 221, 221, 0);
	border-top-color: #dddddd;
	border-width: 12px;
	margin-left: -11px;
}

.box-target-chart {
    padding-top: 25px;
}

.chart-note {
    position: absolute;
    right: 10%;
    top: 35%;
}

.chart-note.text-left > p > i {
    font-size: 25px;
}
.chart-note > p > i:first-child {
    color: red;
}

#social-chart {
    height: 450px;
    position: relative;
    text-align: center;
}

#social-chart .social-chart-note {
    width: 15%;
    position: absolute;
    right: 10%;
    top: 20%;
    font-size: 18px;
    line-height: 40px;
    text-align: left;
}

.social-chart-note div {
    font-size: 20px;
}

span.fb-note {
    color: #FF727F;
    margin-right: 10px;
}
span.tw-note {
    color: #00A3E8;
    margin-right: 10px;
}
span.in-note {
    color: #803EEC;
    margin-right: 10px;
}
.center-title {
    position: absolute;
    top: 200px;
    left: 50%;
    margin-left: -6.55em;
    text-align: center;
    width: 170px;
}

.center-title h3 {
    margin-top: 10px;
    margin-bottom: 10px;
}

/* ==== d3 graph target ===== */
.arcPathBg {
    opacity: 0.1;
}

.donut-text {
    fill: #444;
    font-size: 18px;
}

.valueText {
    fill: #444;
    font-size: 18px;
}

.seperatorText {
    fill: #aaa;
    font-size: 12px;
}

.seperator {
    font-size: 18px;
    fill: #aaa;
}

.maxText {
    fill: #555;
    font-size: 12px;
}

/* ========d3 line chart======== */
.line {
    fill: none;
    stroke: #00A0E7;
    stroke-width: 3;
}

.line-avg {
    fill: none;
    stroke: #FFB44E;
    stroke-width: 2;
}

.area {
    fill: url(#temperature-gradient);
}

.tick line {
    opacity: 0.2;
    stroke-dasharray: 3, 2;
    stroke: #00A0E7;
}

.axis-tick .tick line {
    opacity: 1;
    stroke: rgba(54, 162, 235, 0.3);
    stroke-dasharray: 0, 0;
    stroke-width: 2;
}

.axis-tick path {
    stroke: rgba(54, 162, 235, 0.2);
    stroke-width: 2;
}

.axis-text text {
    font-size: 15px;
    color: #ccc;
}

.dot {
	fill: #FFFFFF;
	stroke: #00A0E7;
	stroke-width: 2;
}

.dot:hover {
	r: 10;
    stroke-width: 3;
}

/* Tooltip text */
.tooltiptext {
    width: 90px;
    height: 40px;
    background-color: white;
    color: #000;
    text-align: center;
    padding: 3px 3px;
    border-radius: 5px;
    -webkit-box-shadow: 0px 0px 27px 0px rgba(54,162,235,0.46);
    -moz-box-shadow: 0px 0px 27px 0px rgba(54,162,235,0.46);
    box-shadow: 0px 0px 27px 0px rgba(54,162,235,0.46);

    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
    top: 30%;
    left: 30%;
}

.tooltiptext {
    bottom: 100%;
    left: 50%;
}

.tooltiptext::after {
    content: " ";
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -8px;
    border-width: 8px;
    border-style: solid;
    border-color: white transparent transparent transparent;
}

.axis-text path {
    display: none;
    stroke-width: 0;
}

.y.axis{
    fill: none;
}

.yaxis {
    font-size: 15px;
    color: #555;
    stroke-width: 0;
}

.grid path {
    stroke: none;
}

.d3-line-chart {
    position: relative;
    width: 100%;
}
